<template>
  <div id="app">
    <header class="header">
      <div class="logo-container">
        <img src="@/assets/pizzanap_logo.svg" alt="Pizzanap Logo" class="logo" />
        <h1 class="slogan">Find the best Neapolitan pizza in your city</h1>
      </div>
      <div class="search-container">
        <select v-model="selectedCityId" @change="loadPizzaPlaces" class="search-input">
          <option value="">Select a city</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
        </select>
      </div>
      <button @click="togglePremiumView" class="premium-button">
        {{ showPremiumView ? 'Back to Map' : 'Get Premium' }}
      </button>
    </header>
    <div class="search-underline"></div>

    <main class="main-content">
      <template v-if="!showPremiumView">
        <div class="map-container">
          <GoogleMap
            :api-key="googleApiKey"
            style="width: 100%; height: 100%"
            :center="mapCenter"
            :zoom="12"
            :disableDefaultUi="true"
            :zoomControl="true"
            :options="mapOptions"
          >
            <!-- User location marker -->
            <CustomMarker
              v-if="userLocation"
              :options="{ position: userLocation }"
            >
              <div class="user-marker">
                <div class="user-marker-icon"></div>
                <div class="user-marker-pulse"></div>
              </div>
            </CustomMarker>

            <!-- Pizza place markers -->
            <CustomMarker
              v-for="place in validPizzaPlaces"
              :key="place.pizza_place_id"
              :options="{ position: place.position }"
              @click="highlightPlace(place.pizza_place_id)"
            >
              <div :class="['custom-marker', { 'highlighted': highlightedPlaceId === place.pizza_place_id, 'faded': highlightedPlaceId && highlightedPlaceId !== place.pizza_place_id }]">
                <img src="@/assets/pizzanap_logo-min.png" alt="Pizza Marker" width="30" height="30" />
                <div v-if="highlightedPlaceId === place.pizza_place_id" class="marker-label">{{ truncateName(place.name) }}</div>
              </div>
            </CustomMarker>
          </GoogleMap>
        </div>

        <div class="pizza-list-container" ref="pizzaListContainer">
  <div class="filter-sort-container">
    <button @click="toggleOpenNow" class="filter-button">
      {{ showOnlyOpenNow ? 'Show All' : 'Open Now' }}
    </button>
    <button @click="toggleSortByDistance" class="sort-button">
      {{ sortByDistance ? 'Unsort' : 'Sort by Distance' }}
    </button>
  </div>

  <div v-if="filteredPizzaPlaces.length === 0" class="no-places-message">
    {{ showOnlyOpenNow ? 'No places open now. Try showing all places.' : 'No places found in this location.' }}
  </div>
  
  <div v-else>
    <div class="pizza-list">
      <div
        v-for="place in paginatedPizzaPlaces"
        :key="place.pizza_place_id"
        :ref="el => { if (el) cardRefs[place.pizza_place_id] = el }"
        :class="['pizza-card', { 'highlighted': highlightedPlaceId === place.pizza_place_id }]"
        @click="highlightPlace(place.pizza_place_id)"
      >
        <div class="image-container">
          <img :src="place.photo_path || 'https://via.placeholder.com/150'" alt="Pizza Place" class="pizza-img" />
          <div class="image-overlay">
            <p>Score: {{ place.rating }}/10</p>
            <p>Price: {{ '$'.repeat(place.price_level) }}</p>
            <p>Dist: {{ formatDistanceForUI(place.distance) }}</p>
          </div>
        </div>
        <div class="pizza-info">
          <div class="pizza-info-header">
            <h2>{{ place.name }}</h2>
          </div>
          <a :href="place.website" target="_blank" class="website-link">Website</a>
          <p>{{ place.address }}</p>
        </div>
      </div>
    </div>

    <div v-if="filteredPizzaPlaces.length > 0" class="pagination">
      <button @click="changePage(-1)" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="changePage(1)" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</div>
      </template>

      <PremiumPage v-else />
    </main>

    <footer class="footer">
      <div class="footer-content">
        <p>&copy; 2024 Pizzanap. All rights reserved.</p>
        <div class="social-links">
          <a href="https://x.com/pascalstriangle" target="_blank" rel="noopener noreferrer">X</a>
          <a href="https://www.instagram.com/mlanalytica/" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://mlanalytica.com" target="_blank" rel="noopener noreferrer">mlanalytica</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { GoogleMap, CustomMarker } from 'vue3-google-map'
import axios from 'axios'
import PremiumPage from './components/PremiumPage.vue'

export default {
  name: "App",
  components: { GoogleMap, CustomMarker, PremiumPage },
  setup() {
    const cities = ref([])
    const selectedCityId = ref('')
    const pizzaPlaces = ref([])
    const filteredPizzaPlaces = ref([])
    const mapCenter = ref({ lat: 47.3769, lng: 8.5417 }) // Default to Zurich
    const currentPage = ref(1)
    const totalPages = ref(1)
    const perPage = 9
    const googleApiKey = 'AIzaSyBCvvL5hqK7eA2-iPDAEkZRW3oKf6WP80w'
    const cardRefs = ref({})
    const highlightedPlaceId = ref(null)
    const pizzaListContainer = ref(null)
    const userLocation = ref(null)
    const showPremiumView = ref(false)
    const showOnlyOpenNow = ref(false)
    const sortByDistance = ref(false)
    const originalPizzaPlaces = ref([])

    const mapOptions = {
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }]
        }
      ]
    }

    const loadCities = async () => {
      try {
        const response = await axios.get('/cities.json')
        cities.value = response.data
        console.log('Cities loaded:', cities.value)
      } catch (error) {
        console.error('Error loading cities:', error)
      }
    }

    const loadPizzaPlaces = async () => {
      if (!selectedCityId.value || !userLocation.value) return
      try {
        const response = await axios.get(`/pizza_data/pizza_data_${selectedCityId.value}.json`)
        pizzaPlaces.value = response.data

        const selectedCity = cities.value.find(city => city.id === parseInt(selectedCityId.value))
        if (selectedCity) {
          originalPizzaPlaces.value = pizzaPlaces.value.map(place => ({
            ...place,
            photo_path: `/pizza_photos/${place.pizza_place_id}/${place.photo_name}.jpg`
          })).filter(place => {
            const lat = parseFloat(place.latitude)
            const lng = parseFloat(place.longitude)
            if (isNaN(lat) || isNaN(lng)) return false
            
            const distance = calculateDistance(
              userLocation.value.lat,
              userLocation.value.lng,
              lat,
              lng
            )
            place.distance = distance
            return distance <= selectedCity.radius
          })

          applyFiltersAndSort()

          mapCenter.value = {
            lat: parseFloat(selectedCity.latitude),
            lng: parseFloat(selectedCity.longitude)
          }
        }
      } catch (error) {
        console.error(`Error loading pizza places for city ID ${selectedCityId.value}:`, error)
      }
    }

    const applyFiltersAndSort = () => {
  let result = [...originalPizzaPlaces.value]
  
  if (showOnlyOpenNow.value) {
    result = result.filter(place => isPlaceOpenNow(place))
  }

  if (sortByDistance.value) {
    result.sort((a, b) => a.distance - b.distance)
  }

  filteredPizzaPlaces.value = result
  currentPage.value = 1 // Reset to first page after filtering/sorting
  totalPages.value = Math.max(1, Math.ceil(result.length / perPage))
}


    const isPlaceOpenNow = (place) => {
      const currentDay = new Date().getDay() // Sunday is 0, Monday is 1, etc.
      const currentTime = new Date().toTimeString().split(' ')[0] // Current time in HH:MM:SS format

      const openingHoursForToday = place.opening_hours.find(
        hours => parseInt(hours.day_of_week) === currentDay
      )

      if (!openingHoursForToday) return false

      const openTime = openingHoursForToday.open_time
      const closeTime = openingHoursForToday.close_time

      return currentTime >= openTime && currentTime <= closeTime
    }

    const calculateDistance = (lat1, lng1, lat2, lng2) => {
      const R = 6371 // Radius of the Earth in km
      const dLat = (lat2 - lat1) * Math.PI / 180
      const dLng = (lng2 - lng1) * Math.PI / 180
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      return R * c // Distance in km
    }

    const formatDistanceForUI = (distance) => {
      if (distance > 30) {
        return '> 30km'
      } else if (distance < 1) {
        return `${Math.round(distance * 1000)} m`
      } else {
        return `${distance.toFixed(1)} km`
      }
    }

    const getUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            userLocation.value = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            mapCenter.value = userLocation.value
            loadPizzaPlaces() // Reload pizza places with new user location
          },
          error => {
            console.error("Error getting user location:", error)
            // Fallback to Zurich
            userLocation.value = { lat: 47.3769, lng: 8.5417 }
            mapCenter.value = userLocation.value
            loadPizzaPlaces()
          }
        )
      } else {
        console.log("Geolocation is not available in this browser.")
        // Fallback to Zurich
        userLocation.value = { lat: 47.3769, lng: 8.5417 }
        mapCenter.value = userLocation.value
        loadPizzaPlaces()
      }
    }

    const highlightPlace = (placeId) => {
      highlightedPlaceId.value = placeId
      const placeIndex = filteredPizzaPlaces.value.findIndex(place => place.pizza_place_id === placeId)
      if (placeIndex !== -1) {
        currentPage.value = Math.floor(placeIndex / perPage) + 1
        nextTick(() => {
          scrollToCard(placeId)
        })
      }
    }

    const scrollToCard = (pizzaPlaceId) => {
      const card = cardRefs.value[pizzaPlaceId]
      if (card && pizzaListContainer.value) {
        const containerRect = pizzaListContainer.value.getBoundingClientRect()
        const cardRect = card.getBoundingClientRect()
        
        pizzaListContainer.value.scrollTo({
          top: cardRect.top - containerRect.top + pizzaListContainer.value.scrollTop,
          behavior: 'smooth'
        })
      }
    }

    const changePage = (direction) => {
      currentPage.value += direction
    }

    const paginatedPizzaPlaces = computed(() => {
      const start = (currentPage.value - 1) * perPage
      const end = start + perPage
      return filteredPizzaPlaces.value.slice(start, end)
    })

    const validPizzaPlaces = computed(() => {
      return filteredPizzaPlaces.value.filter(place => {
        const lat = parseFloat(place.latitude)
        const lng = parseFloat(place.longitude)
        return !isNaN(lat) && !isNaN(lng)
      }).map(place => ({
        ...place,
        position: {
          lat: parseFloat(place.latitude),
          lng: parseFloat(place.longitude)
        }
      }))
    })

    const truncateName = (name) => {
      return name.length > 10 ? name.substring(0, 10) + '...' : name
    }

    const togglePremiumView = () => {
      showPremiumView.value = !showPremiumView.value
    }


    const toggleOpenNow = () => {
      showOnlyOpenNow.value = !showOnlyOpenNow.value
      applyFiltersAndSort()
    }

    const toggleSortByDistance = () => {
      sortByDistance.value = !sortByDistance.value
      applyFiltersAndSort()
    }


    onMounted(() => {
      loadCities()
      getUserLocation()
    })

    watch([selectedCityId, userLocation], () => {
      if (selectedCityId.value && userLocation.value) {
        loadPizzaPlaces()
      }
    })
    return {
      cities,
      selectedCityId,
      pizzaPlaces,
      filteredPizzaPlaces,
      paginatedPizzaPlaces,
      validPizzaPlaces,
      mapCenter,
      currentPage,
      totalPages,
      changePage,
      scrollToCard,
      googleApiKey,
      cardRefs,
      mapOptions,
      highlightedPlaceId,
      highlightPlace,
      pizzaListContainer,
      formatDistanceForUI,
      truncateName,
      showPremiumView,
      togglePremiumView,
      loadPizzaPlaces,
      showOnlyOpenNow,
      sortByDistance,
      toggleOpenNow,
      toggleSortByDistance,
      userLocation
    }
  }
}
</script>


<style>
/* Add these styles to your existing styles */
.user-marker {
  position: relative;
  width: 20px;
  height: 20px;
}

.user-marker-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #4285F4;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  z-index: 2;
}

.user-marker-pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgba(66, 133, 244, 0.4);
  border-radius: 50%;
  animation: pulse 2s infinite;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

/* ... (your existing styles) ... */
</style>