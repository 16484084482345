<template>
  <div class="premium-page">
    <h2>Boost Your Pizzeria with Pizzanap Premium</h2>
    <p>Enhance your visibility and grow your business for just $49/month!</p>
    
    <div class="features">
      <div class="feature">
        <h3>Priority Listing</h3>
        <p>Get featured at the top of search results in your area.</p>
      </div>
      <div class="feature">
        <h3>Detailed Analytics</h3>
        <p>Gain insights into customer preferences and traffic patterns.</p>
      </div>
      <div class="feature">
        <h3>Custom Profile</h3>
        <p>Showcase your unique offerings with an enhanced business profile.</p>
      </div>
      <div class="feature">
        <h3>Special Promotions</h3>
        <p>Run targeted promotions to attract new customers.</p>
      </div>
      <div class="feature">
        <h3>Review Management</h3>
        <p>Easily respond to customer reviews and improve your rating.</p>
      </div>
      <div class="feature">
        <h3>Integration Support</h3>
        <p>Seamlessly connect with your existing ordering systems.</p>
      </div>
    </div>

    <a href="https://buy.stripe.com/7sI7sL6uLaVY7Fm9AA?locale=en" class="subscribe-button">
      Upgrade Your Pizzeria - $49/month
    </a>
  </div>
</template>

<script>
export default {
  name: 'PremiumPage'
}
</script>

<style scoped>
.premium-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #F7F5DF;
  color: #413E3A;
  border-radius: 10px;
}

h2 {
  color: #D32F2F;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.feature {
  text-align: center;
  padding: 1.5rem;
  background-color: #413E3A;
  color: #F7F5DF;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.subscribe-button {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background-color: #D32F2F;
  color: #F7F5DF;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.subscribe-button:hover {
  background-color: #B71C1C;
}
</style>