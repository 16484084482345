import { createApp } from 'vue'
import App from './App.vue'
import './styles/App.css'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(VueGtag, {
  config: { id: 'G-Q45RRRHFHT' }  // Replace with your Google Analytics 4 Measurement ID
})

app.mount('#app')